<template>
    <main class="w-full px-4 py-20 flex flex-col justify-start items-center bg-[#f8f8fa]">
        <section id="home0" class="section" style="background-color: white; margin-top: 5%">
            <div class="content">
                <div class="left" style="margin-left: -13%">
                    <h1 class="max-w-[80%] pt-6 pb-2 text-2xl lg:text-4xl font-bold bg-gradient-to-r from-[#5d5fef] to-[#3db5e6] inline-block text-transparent bg-clip-text" style="text-align: start">
                        A SCIENCE-BACKED HIRING SOLUTION
                    </h1>

                    <p class="max-w-[80%] pt-6 pb-2 text-2xl lg:text-4xl font-bolder inline-block bg-clip-text">
                        Our commitment is to provide you and your candidates with an enjoyable, accurate and scientifically sound talent assessment experience, so that you can validate and rank your
                        candidates' skills with confidence and accuracy. We achieve this by following a deeply scientific, multi-layered approach to test development and validation.
                    </p>
                    <div class="contact flex flex-col w-full h-full mt-4 flex items-center gap-0">
                        <button
                            @click="() => $router.push('/register')"
                            class="btn-primary py-2 px-4 rounded-xl text-white bg-gradient-to-r from-[#5d5fef] to-[#3db5e6] border border-[#3db5e6] hover:bg-none hover:text-[#263238] duration-300"
                        >
                            {{ $t("Try for free") }}
                        </button>
                        <button
                            @click="() => $router.push('/register')"
                            class="btn-secondary py-2 px-4 text-[18px] rounded-xl text-[#263238] border border-[#3db5e6] hover:bg-gradient-to-r from-[#5d5fef] to-[#3db5e6] hover:text-white duration-300"
                        >
                            {{ $t("Book a demo") }}
                        </button>
                    </div>
                </div>
                <div class="right" style="margin-right: -13%">
                    <div class="box">
                        <img src="../assets/first.png" alt="Book" class="book" />
                    </div>
                </div>
            </div>
        </section>
        <hr class="h-[5px] bg-[#F5F8FF] w-full" />
        <section id="home2" class="section" style="background-color: #f5f8ff">
            <div class="content">
                <div class="left">
                    <h1 class="max-w-[80%] pt-6 pb-2 text-2xl lg:text-4xl font-bold bg-gradient-to-r from-[#5d5fef] to-[#3db5e6] inline-block text-transparent bg-clip-text" style="text-align: start">
                        Created and validated by experts
                    </h1>

                    <p class="max-w-[80%] pt-6 pb-2 text-2xl lg:text-4xl font-bolder inline-block bg-clip-text">
                        Go's IP (Intellectual Property) development specialists work with subject matter experts to design our tests and write valid questions. We employ rigorous selection processes
                        to find the right experts. Working closely with our IP development specialists, subject matter experts are trained in the science and art of test development, and our team
                        provides developmental edits and guidance to ensure our tests are valid, reliable, fair, and inclusive. Everything is reviewed by a professional copy-editor and an additional,
                        independent subject matter expert for accuracy and technical correctness.
                    </p>
                </div>
                <div class="right">
                    <div class="box">
                        <img src="../assets/smile2.png" alt="Book" />
                    </div>
                </div>
            </div>
        </section>
        <hr class="h-[5px] bg-[#F5F8FF] w-full" />

        <section id="home3" class="section" style="background-color: white">
            <div class="content">
                <div class="left">
                    <h1
                        class="max-w-[80%] pt-6 pb-2 text-2xl lg:text-4xl font-bold bg-gradient-to-r from-[#5d5fef] to-[#3db5e6] inline-block text-transparent bg-clip-text"
                        style="margin-top: 10%; text-align: start"
                    >
                        Continuous improvement for reliable, science-backed tests
                    </h1>
                    <p class="max-w-[80%] pt-6 pb-2 text-2xl lg:text-4xl font-bolder inline-block bg-clip-text">
                        Our work doesn’t stop there. Go's Science and Assessment Innovation Center of Expertise collects and analyzes data for all tests to examine their quality and impact. We
                        continually conduct a variety of psychometric analyses to maintain and improve the reliability, validity, and relevance of our tests. This includes looking at internal
                        consistency, content validity, construct validity, face validity, and criterion-related validity.
                    </p>
                </div>
                <div class="right">
                    <div class="box">
                        <img src="../assets/stati1.png" alt="Book" />
                    </div>
                </div>
            </div>
        </section>

        <section id="home4" class="section" style="background-color: white">
            <div class="content">
                <div class="right">
                    <div class="box">
                        <img src="../assets/stati4.png" alt="Book" />
                    </div>
                </div>

                <div class="left">
                    <h1
                        class="max-w-[80%] pt-6 pb-2 text-2xl lg:text-4xl font-bold bg-gradient-to-r from-[#5d5fef] to-[#3db5e6] inline-block text-transparent bg-clip-text"
                        style="margin-top: 10%; text-align: start"
                    >
                        Predicting job success with data
                    </h1>
                    <p class="max-w-[80%] pt-6 pb-2 text-2xl lg:text-4xl font-bolder inline-block bg-clip-text">
                        Our test and skill libraries draw on the US Department of Labor’s skills database (O*NET OnLine) as well as the European Commission’s European Skills, Competences,
                        Qualifications and Occupations (ESCO). In addition to working with multiple subject matter experts to develop our tests, we draw on these well-established frameworks to ensure
                        the content validity (what a test evaluates) of each of our tests. We also conduct independent validation studies and collect data and feedback from our customers about the
                        performance of candidates they hire. This helps us evaluate the predictive validity of our tests.
                    </p>
                </div>
            </div>
        </section>
        <section id="home5" class="section" style="background-color: white">
            <div class="content">
                <div class="left">
                    <h1
                        class="max-w-[80%] pt-6 pb-2 text-2xl lg:text-4xl font-bold bg-gradient-to-r from-[#5d5fef] to-[#3db5e6] inline-block text-transparent bg-clip-text"
                        style="margin-top: 10%; text-align: start"
                    >
                        Aligned with industry best practices
                    </h1>
                    <p class="max-w-[80%] pt-6 pb-2 text-2xl lg:text-4xl font-bolder inline-block bg-clip-text">
                        We follow industry best practices and relevant laws and regulations to maximize the fairness and legal defensibility of our tests, including the Uniform Guidelines on Employee
                        Selection Procedures (UGESP), The Principles for the Validation and Use of Personnel Selection Procedures, and The Standards of Educational and Psychological Testing. Our
                        assessment IP and Science experts also monitor developments in academic research, governmental regulation, and industry standards to ensure that our processes maintain
                        alignment.
                    </p>
                </div>
                <div class="right">
                    <div class="box">
                        <img src="../assets/stati3.png" alt="Book" />
                    </div>
                </div>
            </div>
        </section>
        <section id="home6" class="section" style="background-color: white">
            <div class="content">
                <div class="right">
                    <div class="box">
                        <img src="../assets/stati6.png" alt="Book" />
                    </div>
                </div>

                <div class="left">
                    <h1
                        class="max-w-[80%] pt-6 pb-2 text-2xl lg:text-4xl font-bold bg-gradient-to-r from-[#5d5fef] to-[#3db5e6] inline-block text-transparent bg-clip-text"
                        style="margin-top: 10%; text-align: start"
                    >
                        Reduce hiring bias
                    </h1>

                    <p class="max-w-[80%] pt-6 pb-2 text-2xl lg:text-4xl font-bolder inline-block bg-clip-text">
                        We’ve designed our platform and tests considering the standards of the Equal Employment Opportunity Commission (EEOC) and Uniform Guidelines on Employee Selection Procedures
                        (UGESP) to mitigate unconscious bias from the hiring process. We actively monitor our platform and tests to ensure our assessments are fair and unbiased across various
                        demographics such as gender, race, and age.
                    </p>
                </div>
            </div>
        </section>
        <hr class="h-[5px] bg-[#F5F8FF] w-full" />
        <section id="home7" class="section" style="background-color: #f5f8ff">
            <div class="content">
                <div class="left">
                    <h1
                        class="max-w-[80%] pt-6 pb-2 text-2xl lg:text-4xl font-bold bg-gradient-to-r from-[#5d5fef] to-[#3db5e6] inline-block text-transparent bg-clip-text"
                        style="margin-top: 10%; text-align: start"
                    >
                        A great candidate experience
                    </h1>

                    <p class="max-w-[80%] pt-6 pb-2 text-2xl lg:text-4xl font-bolder inline-block bg-clip-text">
                        Go’s simple, streamlined design lets your candidates focus on what matters most: showing their skills. Our assessments provide candidates with job-relevant and realistic
                        scenarios that closely mimic the experience of working in the positions they’re applying for.
                    </p>
                </div>

                <div class="right">
                    <div class="box">
                        <img src="../assets/go.jpeg" alt="Book" style="width: auto; object-fit: cover; height: auto" />
                    </div>
                </div>
            </div>
        </section>
        <hr class="h-[5px] bg-[#F5F8FF] w-full" />
        <section id="home8" class="section" style="background-color: white">
            <div class="content">
                <div class="left">
                    <h1
                        class="max-w-[80%] pt-6 pb-2 text-2xl lg:text-4xl font-bold bg-gradient-to-r from-[#5d5fef] to-[#3db5e6] inline-block text-transparent bg-clip-text"
                        style="margin-top: 10%; text-align: start"
                    >
                        All skills, no frills
                    </h1>

                    <p class="max-w-[80%] pt-6 pb-2 text-2xl lg:text-4xl font-bolder inline-block bg-clip-text">
                        Finally, we put just as much thought and care into the reports and insights we deliver you as we do our tests. In just a few clicks, Go enables you to quickly understand and
                        evaluate your candidates’ strengths and weaknesses. Our platform and reports help you focus on accurate, data-driven hiring decisions that will reduce mis-hires and positively
                        impact the long-term success of your organization.
                    </p>

                    <div class="contact flex flex-col w-full h-full mt-4 flex items-center gap-0">
                        <button
                            @click="() => $router.push('/register')"
                            class="btn-primary py-2 px-4 rounded-xl text-white bg-gradient-to-r from-[#5d5fef] to-[#3db5e6] border border-[#3db5e6] hover:bg-none hover:text-[#263238] duration-300"
                        >
                            {{ $t("Try for free") }}
                        </button>
                        <button
                            @click="() => $router.push('/register')"
                            class="btn-secondary py-2 px-4 text-[18px] rounded-xl text-[#263238] border border-[#3db5e6] hover:bg-gradient-to-r from-[#5d5fef] to-[#3db5e6] hover:text-white duration-300"
                        >
                            {{ $t("Book a demo") }}
                        </button>
                    </div>
                </div>
                <div class="right">
                    <div class="box">
                        <img src="../assets/asses.gif" alt="Book" style="width: 600px; height: 330px; margin-top: 80px" />
                    </div>
                </div>
            </div>
        </section>
        <hr class="h-[5px] bg-[#F5F8FF] w-full" />

        <div v-if="loading" style="width: 100%; height: 500px; display: flex; justify-content: center; align-items: center">
            <LoadingComponent />
        </div>
    </main>
</template>

<script>
import LoadingComponent from "../components/LoadingComponent.vue";

export default {
    name: "SciencePage",
    components: { LoadingComponent },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500;700&display=swap");

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Arial, Helvetica, sans-serif;
}

a {
    text-decoration: none;
}

ul {
    list-style: none;
}

:root {
    --primary: rgb(89, 0, 255);
    --secondary: #ff8800;
}

.main {
    width: 100%;
}

.section {
    width: 100%;
    padding: 20px;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
}

.content {
    display: flex;
    justify-content: space-around;
    padding: -50px;
    flex-wrap: wrap;
}

.left {
    flex: 1;
    min-width: 300px;
    max-width: 600px;
    margin: 20px;
}

/*.left h1 {
    font-size: 2.5rem;
    text-transform: uppercase;
    color: #1f1f1f;
    font-weight: bolder;
    text-align: center;

    line-height: 1.2;
    margin-top: 3%;
}
*/
.left p {
    font-size: 1rem;
    font-weight: 500;
    margin: 20px 0;
    text-align: start;
}

.right {
    flex: 1;
    min-width: 300px;
    max-width: 600px;
    margin: 20px;
}

.box {
    display: flex;
    justify-content: center;
    align-items: center;
}

.book {
    width: 100%;

    object-fit: cover;
    border-radius: 10px;
}

.btn-primary,
.btn-secondary {
    display: inline-block;
    width: 48%;
    padding: 10px 20px;
    margin: 10px;
    font-size: 1rem;
    text-align: center;
    border-radius: 5px;
    font-weight: bold;
}

.btn-primary {
    // background: linear-gradient(to right, #5d5fef, #3db5e6);
    //color: white;
}
.Nav_btn {
    display: flex;
    // justify-content: space-between;
    flex-basis: 22%;
    width: 100%;
    height: 100%;
}

.btn-secondary {
    // background-color: white;
    color: #2196f3;
    border: 1px solid #2196f3;
}

.loading-container {
    width: 100%;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 768px) {
    .left h1 {
        font-size: 2rem;
    }
    .left p {
        font-size: 0.9rem;
    }
    .content {
        margin-top: 20%;
    }

    #home0 {
        height: 250%;
        div.left {
            margin-left: 13%;
        }

        div.right {
            margin-right: 13%;
        }
    }

    #home2 {
        margin-top: 60%;
    }
}

@media (max-width: 576px) {
    #home2 {
        margin-top: 60%;
    }

    .section {
        height: fit-content;
    }
    .left h1 {
        font-size: 1.5rem;
        text-align: start;
    }
    .content {
        margin-top: 15%;
    }
    .left p {
        font-size: 0.8rem;
    }
    .btn-primary,
    .btn-secondary {
        width: 100%;
    }

    #home0 {
        height: 250%;
        div.left {
            margin-left: 13%;
        }

        div.right {
            margin-right: 13%;
        }
    }
}

.box {
    position: relative;
}
.book {
    position: absolute;
    //top: -80px;
    //left: -80px;
    -webkit-animation: updown 3s infinite;
    animation: updown 3s infinite;
}

@-webkit-keyframes updown {
    0% {
        top: -40%;
    }
    50% {
        top: 0;
    }
    100% {
        top: -40%;
    }
}

@keyframes updown {
    0% {
        top: -40px;
    }
    50% {
        top: 0;
    }
    100% {
        top: -40px;
    }
}
</style>
