<template>
    <main class="w-full px-4 py-10 lg:py-20 flex flex-col space-y-10 lg:space-y-20 bg-white">
        <!-- TERMS OF USE Section -->
        <h2 class="text-3xl lg:text-3lg font-bold p-6 mt-10">CUSTOMER AND CANDIDATES TERMS OF USE</h2>
        <div class="terms-of-use-section p-6 rounded-lg">
            <p class="text-sm lg:text-base font-semibold mb-6">Version v1.0 – January 1st, 2024</p>
            <h3 class="text-lg lg:text-2xl font-semibold mb-4">Introduction</h3>
            <p class="text-sm lg:text-base mb-6">
                These GO PLATFORM Terms of Service (hereafter the “Terms”), outline the terms regarding Your use of the Services and are an integral part of the agreement between you (“you”, “your,”
                or “Customer) and GO PLATFORM B.V. (“GO PLATFORM”, “us”, “we”, or “our”). You or GO PLATFORM may also be referred to individually as a “Party” and together as “Parties” in these Terms.
                An “Affiliate” means any company or other entity, whether or not a legal person, which directly or indirectly controls, is controlled by or is under joint control with that person. For
                the purpose of this definition, “control” means (a) any direct or indirect ownership of over 50% of the ownerships, or (b) in the absence of such ownership interest, the power to
                direct or cause the direction of the management and set the policies of such company or entity.
            </p>
            <p class="text-sm lg:text-base mb-6">
                These Terms are a legally binding contract between You and GO PLATFORM. If you do not agree with these Terms, do not register for a GO PLATFORM account, or purchase or use Services. By
                using or accessing the Services, or registering for a GO PLATFORM account, you agree to be bound by these Terms. If you use the Services on behalf of an entity, you agree to these
                Terms for that entity and represent and warrant to GO PLATFORM that you have the authority to bind that entity to these Terms, unless that entity has a separate paid contract in effect
                with GO PLATFORM, in which event the separate paid contract governs your use of the Services.
            </p>
            <p class="text-sm lg:text-base mb-6">
                In these Terms, we refer collectively to these Terms, the Data Processing Agreement and the applicable Service Level Agreement (“SLA”), other instructions and policies
                (“Documentation”) available on our website (“Website”) and applicable ordering documents (“Order Form”, as deﬁned below) as the “Agreement.”
            </p>
            <h3 class="text-lg lg:text-2xl font-semibold mb-4">1. Grant of access and use</h3>
            <p class="text-sm lg:text-base mb-6">
                1.1. Subject to the terms and conditions of the Agreement (including our receipt of applicable fees) GO PLATFORM hereby grants to you, and you accept, a limited, personal,
                non-exclusive, non-sublicensable, non-transferable, non-assignable license to access and use the Services solely for your own internal business purposes.
            </p>
            <p class="text-sm lg:text-base mb-6">
                1.2. Access and use of the Services are provided to any limited number of individuals taking the tests as provided as part of the Services as authorized by the Customer under the
                rights granted pursuant to the Agreement as specified in the Order Form (“Candidates”).
            </p>
            <p class="text-sm lg:text-base mb-6">
                1.3. Subject to prior written approval by GO PLATFORM, your Affiliates may use the Services without entering into a separate Order Form by providing such Affiliate(s) a login ID and
                password to access and use the Services. The Agreement shall apply to each Affiliate with access to your account, and you are directly and primarily responsible for all access to and
                use of the Services by your Affiliates. References in these Terms to you, shall include a reference to your Affiliates.
            </p>
            <h3 class="text-lg lg:text-2xl font-semibold mb-4">2. The Services</h3>
            <p class="text-sm lg:text-base mb-6">2.1. Description. GO PLATFORM offers pre-employment testing services and software as described in the Agreement (the “Services”).</p>
            <p>
                2.2. The “Services” include all products and services provided by GO PLATFORM that (a) have been ordered by the Customer under any applicable ordering document (either via the Website
                or otherwise) that specifies pricing and other commercial terms (“Order Form”); or (b) is used by you. Our Services are not intended for personal or private individual use but are
                designed and intended for commercial use only.
            </p>

            <p>
                2.3. GO PLATFORM will provide the Services in accordance with (a) the terms of the Agreement; and (b) in accordance with the applicable laws; and (c) the Information Security Measures.
            </p>

            <p style="margin-bottom: 3%">
                2.4. We may provide the Services, or a portion thereof, to you through our Affiliates in accordance with these Terms and any applicable Order Form(s). GO PLATFORM shall remain
                responsible at all times for any Services or part thereof, provided by our Affiliates.
            </p>

            <h3 class="text-lg lg:text-2xl font-semibold mb-4">3. Your Account</h3>
            <p>
                3.1. In order to use our Services, it is required to create an account. To create an account, you (i) must be legally authorized to represent the company or business contracting our
                Services; and (ii) must review and accept these Terms on the Customer’s behalf. To create an account, you must provide your email address and create a password. You agree to provide us
                with information that is accurate, complete, and current during your use of the Services. Failure to do so constitutes a breach of the Terms, which may result in termination of your
                account on our Service.
            </p>

            <p>
                3.2. You are responsible for safeguarding the password that you use to access the Service and for any activities or actions under your password. You agree not to disclose your password
                to any third party.
            </p>

            <p style="margin-bottom: 3%">
                3.3. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account. You are responsible for preventing unauthorized access to or use
                of the Services through your account and will notify GO PLATFORM immediately of any such unauthorized access or use. GO PLATFORM is not liable for any loss or damage arising from
                unauthorized use of your account.
            </p>

            <h3 class="text-lg lg:text-2xl font-semibold mb-4">4. User rights and responsibilities</h3>
            <p>4.1. In using the Services, you agree to use the Services only in accordance with these Terms and any applicable Documentation, Order Form(s), and applicable laws.</p>

            <p>
                4.2. You will be solely responsible for all use of the Services under your account including all applications, web domains, devices, and communication channels owned or controlled by
                you or owned or controlled by third parties and made available by you to the Candidates which access, use, interact with, or depend on the Services (each, a “Customer Application”).
            </p>

            <p>
                4.3. You shall not: (a) duplicate any portion of the Services, or any documentation (except for your internal use); (b) modify, translate, decompile, reverse engineer, disassemble,
                adapt the Services, or attempt to derive the source code of the software offered through the Services; (c) use the Services, or allow the transfer, transmission, export, or re-export
                of the Services or portion thereof in violation of any applicable law, regulation or rule; (d) develop any software or service that is derived from the Services and materially similar
                in function to or competes with the Services; (e) bypass, hack or breach any security device or protection used by the Services or access or use the Services, with or without automated
                means (such as scrape, crawl or spider); (f) remove, modify or obscure any identification or proprietary or restrictive rights markings or notices from the Services or any component
                thereto; (g) input, upload, transmit, or otherwise provide to or through the Services, any information or materials that are unlawful or injurious, including the distribution or
                publication of information that is in violation of applicable law, contrary to public order or public morality, or contain, transmit, or activate any software, hardware, or other
                technology, device, or means, including any virus, worm, malware, or other malicious computer code; or (h) aid or assist any third parties in doing any of the above.
            </p>

            <p>
                4.4. You will not lease, (re)sell, (sub)license, assign, distribute, publish, transfer, or otherwise make available any Services to third parties (except Candidates), unless explicitly
                permitted under the Agreement.
            </p>

            <p>4.5. The Services can only be used by Candidates that are at least sixteen (16) years old.</p>

            <p style="margin-bottom: 3%">
                4.6. If you or any of the Candidates or any other person authorized to use the Services through your account violates the above restrictions or threatens to violate them, GO PLATFORM
                is entitled to intervene, without prior consultation with you, to terminate the violation or to prevent any imminent danger, for example by disabling the account of the relevant
                Candidates or your access to the Services. You will be liable for any damage caused by your use of the Services through your account in violation of these restrictions. GO PLATFORM is
                at all times entitled to report criminal offenses, directly related to your violation of the restrictions, it has discovered and shall have no liability for any damage to you that may
                result from such reports.
            </p>

            <h3 class="text-lg lg:text-2xl font-semibold mb-4">5. Account suspension</h3>
            <p style="margin-bottom: 3%">
                5.1. We shall have the right to remove any inappropriate content from the Services, limit, or suspend your access to your account and the Services with immediate effect and without
                prior notice in the event that, in our reasonable determination if: (a) your use or the use of Candidates of the Services is for any unlawful, unauthorized or fraudulent purpose; (b)
                you or Candidates are in material breach of any provision of the Agreement; (c) your use of the Services is materially adversely threatening the security, availability or integrity of
                the Services or our ability to provide service to other customers; (d) our provision or your use of the Services is not permitted by applicable laws or regulations; (e) the account
                information you have provided is incorrect or incomplete; or (f) you are in breach of your payment obligations under the Agreement. If your account or use of the Services is suspended
                by us as a result of your actions or omissions pursuant to this article 5 or Article 8 (Fees and Payment Terms), GO PLATFORM does not have any liability for damages or losses
                (including any loss of data or proﬁts), or any other consequences that you may incur as a result. You will remain responsible for the Fees (as deﬁned below) during any suspension.
            </p>

            <h3 class="text-lg lg:text-2xl font-semibold mb-4">6. Maintenance and Downtime, Modifications</h3>
            <p>
                6.1. The Services may occasionally become unavailable due to (a) the performance of scheduled or unscheduled maintenance, modifications, or upgrades; (b) hardware failures, or failures
                of third-party providers; (c) to mitigate or prevent the effects of any threat or attack to the Services or any other network or systems on which the Services rely; or (d) as necessary
                for legal or regulatory reasons. We will use reasonable efforts to communicate any scheduled service outages to you in advance.
            </p>

            <p>
                6.2. Unless specifically stipulated in an Order Form, the SLA or the Website, GO PLATFORM is not liable for any damages, losses (including loss of data or proﬁts), or any other
                consequences incurred as a result of unavailability of Services or the failure to provide notice of unavailability.
            </p>

            <p style="margin-bottom: 3%">
                6.3. We have the right to occasionally modify the features and operations of the Services. If we do, we will use reasonable efforts to inform you. We agree such changes to the Service
                will not materially diminish the overall features or functionality of the Services. Your continued use of the Services following the posting or notice of the changes will constitute
                your acceptance of such changes. If you do not agree to such changes, you must stop using the Services immediately. We will notify you in line with Section 15 (Amendments) if
                applicable legislation requires GO PLATFORM to provide you with specific notice of any such change.
            </p>

            <h3 class="text-lg lg:text-2xl font-semibold mb-4">7. Free Plan and Beta Products</h3>
            <p>
                7.1. We may invite you to test out new or additional products or features of our Services that are not generally available to all of our customers (“Beta Products”) or use our Services
                free of charge (“Free Plan Products”).
            </p>

            <p>7.2. Beta Products and Free Plan Products may contain errors and are provided for limited evaluation only.</p>

            <p>
                7.3. If you have access to Free Plan Products, we will make the Services available to you on a trial basis, free of charge, until the earlier of (a) the end of the free trial period,
                or (b) the start date of any purchased Service subscriptions ordered by you for such Services.
            </p>

            <p>
                7.4. Beta Products and Free Plan Products are provided “AS IS” without warranty of any kind, whether express, implied, statutory, or otherwise. GO PLATFORM specifically disclaims all
                implied warranties of merchantability, noninfringement and fitness for a particular purpose in relation to Beta Products and Free Plan Products.
            </p>

            <p style="margin-bottom: 3%">
                7.5. GO PLATFORM does not have any obligation to provide Beta Products and Free Plan Products to any customer or to our general customer base. GO PLATFORM is entitled to terminate or
                discontinue a Beta Product or Free Plan Product at any moment.
            </p>

            <h3 class="text-lg lg:text-2xl font-semibold mb-4">8. Fees and Payment Terms</h3>
            <p>
                8.1. You shall pay GO PLATFORM all fees as set forth in the pricing section or in accordance with the applicable rates as published on the Website, unless agreed otherwise in writing
                by the Parties in the Order Form (the “Fees“).
            </p>

            <p>
                8.2. The Services are billed on a subscription basis. You will be billed in advance on a recurring and periodic basis (“Billing Cycle”). Billing Cycles are set either on a monthly or
                annual basis, depending on the type of subscription plan you select as indicated in the Order Form or the Website.
            </p>

            <p>
                8.3. All payment obligations are non-cancelable, and Fees and taxes, once paid, are non-refundable. Except as otherwise set forth in the applicable Order Form(s) and subject to Section
                8.9 (payment disputes), you will pay the Fees due under these Terms in accordance with the following applicable payment method: (a) if you elect to remit the Fees using a credit card
                or PayPal, you represent and warrant that you are authorized to use that credit card or PayPal account, that any and all Fees may be billed to that credit card or PayPal account, and
                that payment of such Fees will not be declined; or (b) if you elect to receive invoices and GO PLATFORM approves you for the same, invoices will be sent to you at the frequency set
                forth in the applicable Order Form and you will pay the Fees due within twenty (20) days of the date of the invoice.
            </p>

            <p>
                8.4. You shall provide GO PLATFORM with accurate and complete billing information including full name, address, state, zip code, country, telephone number, and a valid VAT or
                applicable tax registration number and notify us of any changes to such information. By submitting the payment information, you automatically authorize GO PLATFORM to charge all Fees
                incurred through your account to any such selected payment instruments.
            </p>

            <p>
                8.5. Your use of the Services may be subject to specific usage limitations as listed in the Order Form or as otherwise listed in our Documentation (“Usage Limitations”). If your use of
                the Services exceeds the set Usage Limitations, you must pay the additional Fees as listed for such Service in the pricing section of the order form or on our Website (“Overages”).
                Overage will be billed and due in accordance with this Section 8 (Fees and Payment Terms) of these Terms and in accordance with the Billing Cycles.
            </p>

            <p>
                8.6. All Fees, Overage and other amounts payable by you under the Agreement are exclusive of taxes, duties, levies and similar assessments as well as any other costs including
                transaction costs or bank transfer fees. Without limiting the foregoing, you are responsible for all sales, use, and excise taxes, and any other similar taxes, duties, and charges of
                any kind imposed by any governmental or regulatory authority on any amounts payable by you hereunder, other than any corporate income taxes imposed on GO PLATFORM’s income.
            </p>

            <p>
                8.7. GO PLATFORM is entitled to invoice you if for any reason the appropriate taxing authorities determine that you are not exempt from any taxes and GO PLATFORM is required to pay
                such taxes. Any applicable penalties or interest that might be imposed will be added to such invoices. The Fees shall in no event be decreased by any taxes and/or fees of any nature
                owed by you in connection with your purchase of the Services.
            </p>

            <p>8.8. If you fail to make any payment when due then, in addition to all other remedies that may be available:</p>

            <ul>
                <li>
                    GO PLATFORM may charge interest on the past due amount at the rate of 1.5 % per month of the value of the applicable Fees, calculated daily and compounded monthly or, if higher,
                    the maximum rate permitted under applicable law;
                </li>
                <li>
                    You shall reimburse us for all costs incurred by GO PLATFORM in collecting any late payments or interest, including attorneys’ fees, court costs, and collection agency fees; and
                </li>
                <li>
                    If such failure continues for more than thirty (30) days following written notice thereof, we may suspend the performance of the Services until all past due amounts, and interest
                    thereon, have been paid, without incurring any obligation or liability to you or any other person by reason of such suspension.
                </li>
            </ul>

            <p>
                8.9. If you do not agree with any invoice for Fees, you must notify us in writing within ten (10) days of the date of the respective invoice, failure of which shall result in
                acceptance of the invoice and forfeiture of your right to dispute the invoice. All undisputed fees remain due according to schedule.
            </p>

            <p>
                8.10. All amounts payable to GO PLATFORM under the Agreement shall be paid by you in full without any setoff, recoupment, counterclaim, deduction, debit, or withholding for any reason.
            </p>

            <p style="margin-bottom: 3%">
                8.11. We are entitled to increase the Fees annually for any contract year after the first Term with effect from the Renewal Term (as defined below). If you do not agree to any change
                in the Fees, you are entitled to terminate the Agreement before such modifications become effective. Your continued use of the Service after the modification of the Fees comes into
                effect constitutes your acceptance and agreement to pay the modified Fees.
            </p>

            <h3 class="text-lg lg:text-2xl font-semibold mb-4">9. Intellectual Property and Data</h3>
            <p>
                9.1. You acknowledge and agree that we, and our licensors, as applicable, own all rights in and to the Services software, Documentation, the software including any related software
                application or component thereof, or to any associated materials or intellectual property, or in or to any enhancements, modifications, updates, or improvements of any of the foregoing
                (including any feedback you may provide), and all other materials created by us or provided by us to you as part of the Services under the Agreement. The Agreement does not grant to
                Customer any title or right of ownership in or to the Services, or any related software application or component thereof, or to any associated materials or intellectual property,
                including test content and questions, test algorithms and psychological profiling.
            </p>
            <p>
                9.2. You are prohibited to modify, translate, decompile, reverse engineer, disassemble, adapt the Services and the associated intellectual property rights, or attempt to abstract the
                source code from the software underlying the Services or the applications in any other way. You shall not remove or alter any of GO PLATFORM’s proprietary or copyright notices,
                trademarks or logos contained in the Services.
            </p>
            <p>
                9.3. Any data that originates with a Candidate or that is provided as input via the use of the Services by a Candidate including the test results and scores derived from the Services
                (“Candidate Data”) shall be the exclusive property of the Candidate.
            </p>
            <p>
                9.4. All data or input that originates with you (which shall include candidate custom test questions uploaded by you as part of your use of the Services) and materials generated by you
                via the use of the Services with such data under the Agreement shall remain, as between Customer and GO PLATFORM, the exclusive property of Customer (collectively, “Customer Data”).
                Candidate Data is explicitly excluded from Customer Data.
            </p>
            <p>
                9.5. All intellectual property rights in and to any data that is derived from the use of the Services, however excluding Customer Data, and, subject to applicable law, any
                de-identified or anonymized aggregated data, shall be the exclusive property of GO PLATFORM (“GO PLATFORM Data”). GO PLATFORM hereby grants to you, and you accept, a limited-term,
                personal, non-exclusive, non-sublicensable, non-transferable, non-assignable license to access and use GO PLATFORM Data, including results derived from Candidate Data, solely for your
                own use of the Services in accordance with the Agreement.
            </p>
            <p style="margin-bottom: 3%">
                9.6. You grant us and our Affiliates authorized by you to provide Services under these Terms the right to use and process Customer Data only to the extent necessary to provide the
                Services in accordance with the Terms and the Data Processing Agreement. The terms of the Data Processing Agreement are incorporated into these Terms as an Annex. Your agreement to
                these Terms constitutes agreement to the Data Processing Agreement.
            </p>
            <h3 class="text-lg lg:text-2xl font-semibold mb-4">10. Representations, Warranties, and Disclaimer</h3>
            <p>10.1. Each Party shall at all times comply with all applicable laws, rules and regulations in the performance of the Agreement.</p>
            <p>
                10.2. Each Party represents and warrants that it has been duly authorized to enter into the Agreement and it is entitled to perform its obligations and provide the licenses hereunder.
            </p>
            <p>
                10.3. Customer represents and warrants to have obtained all the required permissions or consents to provide Customer Data to GO PLATFORM for use and disclosure in accordance with the
                Agreement.
            </p>
            <p>
                10.4. Customer represents and warrants (a) to use the Services only for lawful purposes and in a manner consistent with these Terms; (b) not to use the Services to assess candidates
                for any jobs which are unlawful, unsafe, offensive, discriminatory, or inappropriate; and (c) not to use the Services to solicit information from candidates that could be used to
                discriminate against them.
            </p>
            <p>
                10.5. GO PLATFORM warrants that the Services will substantially conform in all material respects in accordance with the applicable Documentation and that the Services shall not contain
                or transmit any (a) virus, trojan horse, worm, backdoor, or other software or hardware devices the effect of which is to permit unauthorized access to, or to disable, erase, or
                otherwise harm, any computer, systems, or software; or (b) time bomb, drop dead device, or other software or hardware device designed to disable a computer program automatically with
                the passage of time or under the positive control of any person. GO PLATFORM further warrants and undertakes that: (a) it has and will maintain all necessary licenses, consents, and
                permission necessary for the performance of its obligations under the Agreement; and (b) it will cooperate with Customer in all matters relating to the Services. You will provide
                prompt written notice of any non-conformity. As Customer’s sole and exclusive remedy and GO PLATFORM’s entire liability for any breach of our warranties, GO PLATFORM will at our sole
                discretion either v(a) use reasonable efforts to fix, provide a workaround, or otherwise correct the defect or, if GO PLATFORM is unable to do so, (b) refund the Fees paid to for such
                allegedly defective Services for the period commencing from the receipt of your default notice for the remainder of the time period during which the material failure affected the
                Services.
            </p>
            <p style="margin-bottom: 3%">
                10.6. Except for the warranties expressly provided by us in Sections 10.1 and 10.5, Customer expressly acknowledges and agrees that, to the maximum extent permitted by applicable law,
                the Services are provided “as is” with all faults and without warranty of any kind, and GO PLATFORM hereby disclaims all warranties and conditions with respect to the Services, either
                express, implied or statutory, including, but not limited to, the implied warranties and/or conditions of merchantability, of satisfactory quality, of fitness for a particular purpose,
                or accuracy.
            </p>
            <h3 class="text-lg lg:text-2xl font-semibold mb-4">11. Indemnification</h3>
            <p>
                11.1. We will defend, indemnify and hold Customer harmless against all damages, fines, penalties, settlement amounts pre-approved by us, costs, expenses, taxes, and other liabilities
                (including reasonable attorneys’ fees) incurred or awarded against you in connection with any unaffiliated third-party claim, action, demand, suit, or proceeding made or brought
                against you and your Affiliates, as well as their individual officers, directors, and employees that your use of the Services in accordance with the Agreement infringes copyright,
                registered trademark, issued patent, or other intellectual property right of such third party (“Infringement Claim“), on written demand. We reserve the right to either (a) alter the
                Services to make them non-infringing, or (b) terminate the infringing Services and refund you any Fees, in the event of an Infringement Claim.
            </p>
            <p>
                11.2. Any Infringement Claim resulting from or arising out of (a) your use of the Services in violation of the Agreement; (b) the combination of the Services with other applications,
                products, or services where the Services would not by themselves be infringing; or (c) Beta Products and Free Plan Products do not give rise to any liability or obligation on our part
                under Section 11.1.
            </p>
            <p>
                11.3. Customer will defend, indemnify and hold GO PLATFORM harmless against all damages, fines, penalties, costs, expenses, taxes, and other liabilities (including reasonable
                attorneys’ fees) incurred or awarded against GO PLATFORM, our Affiliates, officers, directors and personnel in connection with any claim by an unaffiliated third party alleging or
                arising out of the (a) breach of your obligations under the Agreement; (b) failure to obtain any appropriate license or other permissions, regulatory certifications, or approvals
                associated with technology or data provided by Customer including Customer Data; (c) failure to comply with your obligations in violation of any applicable law, including data privacy
                laws; (d) infringement or misappropriation of intellectual property rights of third parties; and (e) breach of confidentiality.
            </p>
            <p>
                11.4. The obligation to provide indemnification under the Agreement shall be contingent upon the party seeking indemnification (i) providing the indemnifying party with prompt written
                notice of any claim for which indemnification is sought, (ii) allowing the indemnifying party to control the defense and settlement of such claim, provided however that the
                indemnifying party agrees not to enter into any settlement or compromise of any claim or action in a manner that admits fault or imposes any restrictions or obligations on an
                indemnified party without that indemnified party’s prior written consent which will not be unreasonably withheld, and (iii) cooperating fully with the indemnifying party in connection
                with such defense and settlement.
            </p>
            <p style="margin-bottom: 3%">
                11.5. This Section 11 (Indemnification) states the sole liability of the indemnifying Party to, and the exclusive remedy of the indemnified Party against, the other Party for any
                third-party claims.
            </p>

            <h3 class="text-lg lg:text-2xl font-semibold mb-4">12. Limitation of Liability</h3>
            <p>
                12.1. To the greatest extent permitted by applicable law, each Party’s total accumulated liability to the other or to any third party for any direct loss, damages, costs or expenses
                whether in strict liability, negligence, contract or otherwise relating to this Agreement, shall be limited to the aggregate fees paid or payable by Customer to GO PLATFORM during the
                twelve-month period prior to the occurrence of the initial event giving rise to a claim.
            </p>
            <p>
                12.2. Neither Party shall be liable to the other for any consequential damage and indirect damage, including but not limited to loss, lost sales or lost profits, consequential,
                incidental, special, punitive, and/or contingent damages whatsoever, (whether in contract, tort (including negligence), strict liability, warranty or otherwise), even if such party
                knew or should have known of the possibility of such damages.
            </p>
            <p>
                12.3. GO PLATFORM shall not be liable for Beta Products and Free Plan Products nor for damage, destruction or loss of data or documents (including Customer Data) resulting from the use
                of the Services.
            </p>
            <p style="margin-bottom: 3%">
                12.4. The limitations of liability do explicitly not apply to: (a) Customer’s breach of Section 4 (User Rights and Responsibilities); (b) Customer’s breach of Section 8 (Fees and
                Payment Terms); or (c) the obligations under Section 11 (Indemnification).
            </p>
            <h3 class="text-lg lg:text-2xl font-semibold mb-4">13. Confidentiality</h3>
            <p>
                13.1. In connection with this Agreement, each Party may have access to or be exposed to information of the other Party that is not generally known to the public, such as software,
                product plans, pricing, marketing and sales information, customer lists, “know-how,” or trade secrets, which may be designated as confidential or which, under the circumstances
                surrounding disclosure, ought to be treated as confidential, including Customer Data (collectively, “Confidential Information”).
            </p>
            <p>
                13.2. Confidential Information may not be shared with third parties unless such disclosure is to the receiving Party’s personnel, including employees, agents, and authorized
                subcontractors, on a “need-to-know” basis in connection with this Agreement, so long as such personnel have agreed in writing to treat such Confidential Information under terms at
                least as restrictive as those herein. Each Party agrees to take the necessary precautions to maintain the confidentiality of the other Party’s Confidential Information by using at
                least the same degree of care as such Party employs with respect to its own Confidential Information of a similar nature, but in no case less than a reasonable standard of care to
                maintain confidentiality.
            </p>
            <p style="margin-bottom: 3%">
                13.3. The foregoing shall not apply to information that: (a) was known by the receiving party prior to its receipt from the disclosing party or is or becomes public knowledge through
                no fault of the receiving party; or (b) is rightfully received by the receiving party from a third party without a duty of confidentiality. If a receiving party is required by a court
                or government agency to disclose Confidential Information, the receiving party shall provide reasonable advance notice to the disclosing party before making such disclosure to enable
                the disclosing party to seek an appropriate protective order or other such remedy.
            </p>
            <h3 class="text-lg lg:text-2xl font-semibold mb-4">14. Term and Termination</h3>
            <p>
                14.1. The Agreement including these Terms commence on the date when accepted or on the date as indicated in the Order Form and will continue until all Order Forms or Services used by
                Customer on the Website entered into under these Terms have expired or been terminated.
            </p>
            <p>
                14.2. Your initial subscription duration is specified when the Services are activated through the Website or in the applicable Order Form (“Initial Term”). Unless otherwise provided on
                the Website or in the applicable Order Form, your Subscription will automatically renew for additional successive periods of equal duration to the Initial Term (each, a “Renewal Term”,
                and together with the Initial Term, the “Term”) unless canceled by you or GO PLATFORM. If you do not agree to the renewal, you are entitled to terminate the renewal of the Agreement
                either through the Website or by contacting the contact help center up to the last day of the Term. Your continued use of the Service constitutes your acceptance and agreement to the
                Renewal Term.
            </p>
            <p>
                14.3. The applicable fee for any Renewal Term will be determined using the then-current list price applicable on the Website for such renewed Services unless a different renewal
                pricing is specified in the Order Form.
            </p>
            <p>
                14.4. Either Party may terminate the Agreement and any Order Form (in whole or in part) by providing the other Party with not less than ten (10) days’ prior written notice in the event
                the other Party materially breaches any provision of this Agreement. If the breaching Party fails to cure the material breach within a ten (10) day period following the notice of
                default, the non-breaching Party may terminate this Agreement effective at the end of the ten (10) day period, notwithstanding any other provision in this Agreement. Regarding a
                material breach of Customer, GO PLATFORM may, in addition to termination, suspend the provision of certain Services, close your accounts, and/or prohibit Customer from creating any new
                accounts.
            </p>
            <p>14.5. In no event will termination of the Agreement or Order Form relieve Customer of any payment obligation of the Fees payable prior to the effective date of termination.</p>
            <p>
                14.6. Either Party may terminate this Agreement by written notice with immediate effect in the event the other Party becomes insolvent, or generally unable to pay its debts as they
                become due or makes an assignment for the benefit of its creditors or seeks relief under any bankruptcy, insolvency or liquidation proceedings.
            </p>
            <p>
                14.7. In the event of any expiration or termination of the Agreement: (a) GO PLATFORM will invoice Customer for any accrued but unbilled amounts, and Customer shall promptly pay any
                then outstanding and unpaid amounts including any accrued but unbilled amounts owed under the Agreement; (b) Customer shall immediately cease all use of the Services and return or
                purge any and all components thereof, including returning or destroying or causing to be destroyed any and all copies of the Documentation, notes and other materials comprising or
                regarding the Services and any GO PLATFORM Data; (c) GO PLATFORM will suspend access to the Services and Customer shall no longer have access to GO PLATFORM’s platform including its
                historic assessments; and (d) GO PLATFORM delete to Customer all Customer Data, all Customer Confidential Information and any other material, equipment or information that is
                proprietary to Customer within sixty (60) days after the effective date of expiration or termination, unless it is required to store such data longer as required by applicable law or
                as required to prosecute or defend a legal claim, in which case such information will only be retained for as long as required to resolve the claim.
            </p>
            <p style="margin-bottom: 3%">
                14.8. The following sections and paragraphs shall survive the expiration or termination of this Agreement under Section 14 (Term and Termination): Section 8 (Fees and Payment Terms),
                Section 9 (Intellectual Property and Data), Section 13 (Confidentiality), Section 10.5 (Disclaimer), Section 11 (Indemnification), Section 12 (Limitation of Liability), Section 16
                (General) as well as the Data Processing Agreement.
            </p>

            <h3 class="text-lg lg:text-2xl font-semibold mb-4">15. Amendments</h3>
            <p>
                15.1. GO PLATFORM is entitled to amend these Terms from time to time. We will use reasonable efforts to notify you of any material changes by posting an announcement on the Website or
                by email. To the greatest extent permitted by applicable law, the new Terms will take immediate effect, and your continued use of the Services following our posting or notice of the
                changes will constitute your acceptance of the updated Terms.
            </p>
            <p style="margin-bottom: 3%">
                15.2. If we are required by applicable law to give additional notice, changes will automatically take effect regarding your use of the relevant Services upon the expiry of such notice
                period (unless you terminate during that period) or upon your earlier acceptance of such changes. If you have a right under applicable law to terminate this Agreement upon receipt of
                such notice, you will not be charged a fee for early termination where you exercise that right under applicable law, but any fees previously paid by you are non-refundable and any fees
                owing continue to remain due and payable.
            </p>
            <h3 class="text-lg lg:text-2xl font-semibold mb-4">16. Miscellaneous</h3>
            <p>
                16.1. Compliance with Law Assurances. Both Parties warrant to comply with all applicable anti-corruption, anti-money laundering, sanctions, export controls, and other international
                trade laws, regulations, and governmental orders of the European Union, the United Kingdom, the United States of America, the United Nations, or any other relevant governmental
                authority, including obtaining all necessary licenses and/or government approvals. Parties will promptly notify each other in writing of any actual or potential violation of such
                applicable laws and regulations in connection with the use of the Services and Parties shall take all appropriate actions to remedy or resolve such violations, including any actions
                requested by the other Party.
            </p>
            <p>
                16.2. No Class Actions. To the greatest extent permitted by applicable law, neither Customer nor GO PLATFORM shall be entitled to join or consolidate claims by or against other
                customers or pursue any claim as a representative of a class action or in a private attorney general capacity.
            </p>
            <p>
                16.3. US Government Terms. The Services, including any related software and technology, are provided solely in accordance with these Terms for United States government end use. If you
                (or any users of your Customer Application) are an agency, department, or other entity of the United States government, the use, duplication, reproduction, release, modification,
                disclosure, or transfer of the Services, or any related documentation, is restricted by these Terms. All other use is prohibited and no other rights other than those provided in these
                Terms are conferred.
            </p>
            <p>
                16.4. Independent Contractors. The Parties are independent contractors. No provision of this Agreement will or shall be deemed to create an association, trust, partnership, joint
                venture or other entity or similar legal relationship between GO PLATFORM and Customer, or impose a trust, partnership or fiduciary duty, obligation, or liability on or with respect to
                such entities. Neither Party will have any rights, power, or authority to act or create an obligation, express or implied, on behalf of another party except as specified in the
                Agreement.
            </p>
            <p>
                16.5. Force Majeure. Neither Party shall be liable to the other for any failure to perform any of its obligations (except payment obligations) under the Agreement during any period in
                which such performance is delayed by circumstances beyond its reasonable control, such as fire, flood, war, embargo, strike, riot, terrorism, epidemic or pandemic, or the intervention
                of any governmental authority (a “Force Majeure”). In such event, however, the delayed Party must promptly provide the other Party with written notice of the Force Majeure. The delayed
                Party’s time for performance will be excused for the duration of the Force Majeure, but if the Force Majeure event lasts longer than 90 days, then the other Party may immediately
                terminate, without any liability, in whole or in part, the Agreement by giving written notice to the delayed Party.
            </p>
            <p>
                16.6. Transferability and subcontracting. Neither all nor any part of Customer’s rights or obligations under this Agreement are assignable or transferable by Customer, whether directly
                or indirectly, without the prior written consent of GO PLATFORM, and any attempt to do so shall be void, except in case of merger, acquisition or sale of majority of assets. GO
                PLATFORM has the right to freely assign all or part of its rights and obligations under the Agreement or to make use of the services of third parties by subcontracting. Subject to the
                foregoing, the Agreement shall be binding upon and inure to the benefit of the parties and their respective successors and permitted assigns.
            </p>
            <p>
                16.7. Entire Agreement. The Agreement constitutes the entire agreement between you and us with respect to its subject matter and supersedes all prior oral and written understandings,
                communications, or agreements. General terms and conditions of Customer are not applicable and expressly excluded.
            </p>
            <p>
                16.8. Severability. If any provision of the Agreement should be found to be void or unenforceable, such provision will be stricken or modified, but only to the extent necessary to
                comply with the law, and the remainder of the Agreement will remain in full force and will not be terminated.
            </p>
            <p>
                16.9. Notices. Any notice, request, demand, or other communication to be provided under this Agreement shall be in writing, and shall be sent by the email addresses provided by each
                Party, or at such other address as a Party may designate by written notice to the other Party.
            </p>
            <p>16.10. Headings. The section headings in this Agreement are inserted for convenience of reference only and shall not affect the meaning or interpretation of the Agreement.</p>
            <p>
                16.11. Publicity. You grant us the right to use your name, logo, and a description of your use case to refer to you on our website, customer lists, or marketing or promotional
                materials, subject to your standard trademark usage guidelines expressly provided to us.
            </p>
            <p style="margin-bottom: 3%">
                16.12. Execution. This Agreement may be executed in one or more counterparts, each of which shall be deemed an original, but all of which together shall constitute one and the same
                instrument. Each Party agrees that this Agreement and any other documents to be delivered in connection herewith may be electronically signed and that any electronic signatures
                appearing on this Agreement or such other documents are the same as handwritten signatures for the purposes of validity, enforceability, and admissibility.
            </p>
            <h3 class="text-lg lg:text-2xl font-semibold mb-4">17. Governing law</h3>
            <p>
                17.1. The Agreement including these Terms shall be governed by the laws of the Qatar. The United Nations Convention on Contracts for the International Sale of Goods is explicitly
                excluded.
            </p>
            <p>17.2. The Parties agree that any dispute arising out or relating to the Agreement shall be brought exclusively in the appropriate courts in Amsterdam, the Qatar.</p>
            <!-- Add additional content as needed -->
        </div>
        <div v-if="loading" style="width: 100%; height: 500px; display: flex; justify-content: center; align-items: center">
            <LoadingComponent />
        </div>
    </main>
</template>

<script>
export default {
    name: "TermsPage",
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500;700&display=swap");

main {
    font-family: Gellix-Medium, Helvetica, Arial, sans-serif;
    line-height: 1.6;

    //max-width: 800px;
    //margin-left: 8px;
    //margin-right: 8px;

    //margin-left: auto; margin-right: auto;
    padding-left: 8%;
    padding-right: 8%;
}
h2 {
    font-family: Gellix-Medium, Helvetica, Arial, sans-serif;
    color: #292d2a;
    border-bottom: 1px solid #ddd;
    //padding-bottom: 5px;
    //margin-bottom: 20px;
    text-align: initial;
    font-weight: bold;
    //margin-bottom: 4%;

    text-transform: uppercase;
}
//           h2{
//            font-size: 40px;}
h3 {
    font-family: Gellix-Medium, Helvetica, Arial, sans-serif;
    //            font-size: 2rem;
    //     font-size: 30px;

    text-align: initial;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    color: #292d2a;
}

li {
    font-family: Gellix-Medium, Helvetica, Arial, sans-serif;
    text-align: initial;
    //font-size: 1rem;
}
p {
    font-family: Gellix-Medium, Helvetica, Arial, sans-serif;
    text-align: initial;
    // font-size: 1rem;
    color: #111416;
    line-height: 1.375em;
}
.disclaimer-section {
    //            margin-bottom: 40px;
}
.privacy-notice-section {
    //          margin-bottom: 40px;
}
.cookie-policy-section {
    //        margin-bottom: 40px;
}
.terms-of-use-section {
    //      margin-bottom: 40px;
}
@media only screen and (max-width: 600px) {
    body {
        margin: 10px;
    }
    h2 {
        // font-size: 32px;
    }
}
</style>
