<template>
    <main class="flex flex-row justify-center items-center px-10 py-40">
        <div class="w-4/5 h-[40rem] after:content-('') relative after:absolute after:right-0 after:top-0 after:w-0.5 after:rounded-full after:h-full after:bg-slate-200"></div>
        <div class="w-1/6 h-[40rem] bg-white flex flex-col justify-start gap-3 items-center rounded-md">
            <div class="flex flex-col justify-between items-center w-full rounded-md p-6 gap-4 bg-white mx-auto">
                <h2 class="font-semibold text-base text-left w-full after:content-('') after:absolute relative after:w-full after:h-0.5 after:bg-NeonBlue after:bottom-0 after:left-0 pb-4">
                    <font-awesome-icon class="text-NeonBlue text-lg" :icon="['far', 'file-lines']" /> Type
                </h2>
                <h3 class="font-light text-sm text-left w-full">Programming skills</h3>
            </div>
            <div class="flex flex-col justify-between items-center w-full rounded-md p-6 gap-4 bg-white mx-auto">
                <h2 class="font-semibold text-base text-left w-full after:content-('') after:absolute relative after:w-full after:h-0.5 after:bg-NeonBlue after:bottom-0 after:left-0 pb-4">
                    <font-awesome-icon class="text-NeonBlue text-lg" :icon="['far', 'clock']" /> Time
                </h2>
                <h3 class="font-light text-sm text-left w-full">10 min</h3>
            </div>
            <div class="flex flex-col justify-between items-center w-full rounded-md p-6 gap-4 bg-white mx-auto">
                <h2 class="font-semibold text-base text-left w-full after:content-('') after:absolute relative after:w-full after:h-0.5 after:bg-NeonBlue after:bottom-0 after:left-0 pb-4">
                    <font-awesome-icon class="text-NeonBlue text-lg" :icon="['far', 'flag']" /> Languages
                </h2>
                <h3 class="font-light text-sm text-left w-full">English</h3>
            </div>
            <div class="flex flex-col justify-between items-center w-full rounded-md p-6 gap-4 bg-white mx-auto">
                <h2 class="font-semibold text-base text-left w-full after:content-('') after:absolute relative after:w-full after:h-0.5 after:bg-NeonBlue after:bottom-0 after:left-0 pb-4">
                    <font-awesome-icon class="text-NeonBlue text-lg" :icon="['fas', 'ranking-star']" /> Level
                </h2>
                <h3 class="font-light text-sm text-left w-full">Intermediate</h3>
            </div>
        </div>
    </main>
</template>

<script>
export default {
    name: "TestPage",
};
</script>

<style lang="scss" scoped></style>
