<template>
    <ToastNotification :message="message" :isVisible="isVisible" :bgColor="bgc" />

    <div class="w-full p-2 bg-[#fff] rounded-[8px] mt-5">
        <div class="navigation-tabs">
            <div class="nav-links">
                <router-link to="/companyprofile" :class="`${this.$route.path == '/companyprofile' ? 'active ' : ''}`">
                    <font-awesome-icon :icon="['fas', 'list-check']" class="nav-icon" />
                    <span>Company profile</span>
                </router-link>

                <router-link to="/settings" :class="`${this.$route.path == '/settings' ? 'active ' : ''}`">
                    <font-awesome-icon :icon="['fas', 'chart-gantt']" class="nav-icon" />
                    <span>Recruiter profile</span>
                </router-link>
            </div>
        </div>
        <div class="w-[70%] mx-[15%] flex flex-col gap-[40px] mt-[3%]">
            <div class="flex gap-5">
                <div class="input_group">
                    <input type="text" id="first_name" v-model="newRecruiter.first_name" required name="first_name" />
                    <label
                        class="required floating_label"
                        :class="{
                            active: newRecruiter.first_name,
                        }"
                        for="first_name"
                        ><img loading="lazy" decoding="async" src="../assets/Images/icons/user_icon.svg" alt="" />{{ $t("First Name") }}</label
                    >
                    <span v-if="requiredFields.first_name" class="err_msg">{{ requiredFields.first_name }}</span>
                </div>
                <div class="input_group">
                    <input type="text" id="last_name" v-model="newRecruiter.last_name" required name="last_name" />
                    <label
                        class="required floating_label"
                        :class="{
                            active: newRecruiter.last_name,
                        }"
                        for="last_name"
                        ><img loading="lazy" decoding="async" src="../assets/Images/icons/user_icon.svg" alt="" />{{ $t("Last Name") }}</label
                    >
                    <span v-if="requiredFields.last_name" class="err_msg">{{ requiredFields.last_name }}</span>
                </div>
            </div>
            <div class="flex gap-5">
                <div class="input_group">
                    <input type="text" id="phone_nbr" v-model="newRecruiter.phone_nbr" required name="phone_nbr" />
                    <label
                        class="required floating_label"
                        :class="{
                            active: newRecruiter.phone_nbr,
                        }"
                        for="phone_nbr"
                        ><img loading="lazy" decoding="async" src="../assets/Images/icons/user_icon.svg" alt="" />{{ $t("Phone number") }}</label
                    >
                    <span v-if="requiredFields.phone_nbr" class="err_msg">{{ requiredFields.phone_nbr }}</span>
                </div>
                <div class="input_group">
                    <!-- <input type="text" id="last_name" v-model="newRecruiter.last_name" required name="last_name" />
                    <label
                        class="required floating_label"
                        :class="{
                            active: newRecruiter.last_name,
                        }"
                        for="last_name"
                        ><img loading="lazy" decoding="async" src="../assets/Images/icons/user_icon.svg" alt="" />{{ $t("Last Name") }}</label
                    >
                    <span v-if="requiredFields.last_name" class="err_msg">{{ requiredFields.last_name }}</span> -->
                </div>
            </div>
            <div class="flex justify-end gap-5">
                <button class="py-2 px-4 bg-[#2196F3] text-[16px] font-medium hover:opacity-80 text-[#fff] rounded-[8px]" @click="updateRecruiter">{{ $t("Save") }}</button>
            </div>
            <hr />
            <h1 class="font-medium">Change password</h1>
            <div class="flex gap-5">
                <div class="input_group">
                    <input :type="showPassword ? 'text' : 'password'" id="oldPassword" v-model="newRecruiter.oldPassword" required name="oldPassword" />
                    <button class="showpassword" @click.prevent="showPassword = !showPassword">
                        <img loading="lazy" decoding="async" class="eye-icon" :src="require(`../assets/${showPassword ? 'icon_eye_closed.svg' : 'icon_eye.svg'}`)" width="25" height="25" alt="" />
                    </button>
                    <label
                        class="required floating_label"
                        :class="{
                            active: newRecruiter.oldPassword,
                        }"
                        for="oldPassword"
                        ><img loading="lazy" decoding="async" src="../assets/Images/icons/user_icon.svg" alt="" />{{ $t("Old password") }}</label
                    >
                    <span v-if="requiredFields.oldPassword" class="err_msg">{{ requiredFields.oldPassword }}</span>
                </div>
                <div class="input_group">
                    <input :type="showNewPassword ? 'text' : 'password'" id="newPassword" v-model="newRecruiter.newPassword" required name="newPassword" />
                    <button class="showpassword" @click.prevent="showNewPassword = !showNewPassword">
                        <img loading="lazy" decoding="async" class="eye-icon" :src="require(`../assets/${showNewPassword ? 'icon_eye_closed.svg' : 'icon_eye.svg'}`)" width="25" height="25" alt="" />
                    </button>
                    <label
                        class="required floating_label"
                        :class="{
                            active: newRecruiter.newPassword,
                        }"
                        for="newPassword"
                        ><img loading="lazy" decoding="async" src="../assets/Images/icons/user_icon.svg" alt="" />{{ $t("New password") }}</label
                    >
                    <span v-if="requiredFields.newPassword" class="err_msg">{{ requiredFields.newPassword }}</span>
                </div>
            </div>
            <div class="flex justify-end gap-5">
                <button class="py-2 px-4 bg-[#2196F3] text-[16px] font-medium hover:opacity-80 text-[#fff] rounded-[8px] my-[3%]" @click="updatePassword">{{ $t("Change password") }}</button>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { useStore } from "../store/index";
import ToastNotification from "@/components/ToastNotification";

export default {
    name: "SettingsView",
    components: {
        ToastNotification,
    },
    data() {
        return {
            recruiter: {
                first_name: "",
            },
            newRecruiter: {},
            requiredFields: {},
            showPassword: false,
            showNewPassword: false,
            isVisible: false,
            message: "",
            bgc: "",
        };
    },
    setup() {
        const Store = useStore();
        return { Store };
    },
    methods: {
        async getUser() {
            try {
                axios
                    .get("https://server.go-platform.com/user/info", {
                        withCredentials: true,
                    })
                    .then((res) => {
                        console.log({ res });
                        this.Store.setCompanyName(this.$route.query.company_name);
                        this.user = res.data.userInfo;
                        this.recruiter = res.data.userInfo;
                        this.newRecruiter.first_name = res.data.userInfo.first_name;
                        this.newRecruiter.last_name = res.data.userInfo.last_name;
                        this.newRecruiter.phone_nbr = res.data.userInfo.phone_nbr;
                        this.Store.getCompanyCredit();
                    })
                    .catch((err) => {
                        console.log(err);
                        // Handle error if the token is invalid or expired
                        //this.$router.push('/');
                    });
            } catch (error) {
                console.error(error);
            }
        },
        updateRecruiter() {
            this.requiredFields = {};
            let isValid = true;
            if (!this.newRecruiter.first_name) {
                this.requiredFields.first_name = "First name is required";
                isValid = false;
            }
            if (!this.newRecruiter.last_name) {
                this.requiredFields.last_name = "Last name is required";
                isValid = false;
            }
            if (!this.newRecruiter.phone_nbr) {
                this.requiredFields.phone_nbr = "Phone number is required";
                isValid = false;
            }
            if (isValid) {
                console.log({ newRecruiter: this.newRecruiter });

                axios
                    .put("https://server.go-platform.com/user/update", this.newRecruiter, {
                        withCredentials: true,
                    })
                    .then((res) => {
                        console.log({ res });
                        this.message = "Profile updated successfully";
                        this.bgc = "success";
                        this.isVisible = true;
                        setTimeout(() => {
                            this.isVisible = false;
                        }, 3000);
                        this.getUser();
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        updatePassword() {
            this.requiredFields = {};
            let isValid = true;
            const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{8,}$/;

            if (!passwordRegex.test(this.newRecruiter.oldPassword)) {
                this.requiredFields.oldPassword = "Password must contain at least one uppercase letter, one special character, and be at least 8 characters long.";
                isValid = false; // Update the formValid flag
            }

            // this.requiredFields.newPassword = "New password is required";
            if (!passwordRegex.test(this.newRecruiter.newPassword)) {
                this.requiredFields.newPassword = "Password must contain at least one uppercase letter, one special character, and be at least 8 characters long.";
                isValid = false; // Update the formValid flag
            }

            if (isValid) {
                console.log({ newRecruiter: this.newRecruiter });

                axios
                    .put("https://server.go-platform.com/user/updatePass", this.newRecruiter, {
                        withCredentials: true,
                    })
                    .then((res) => {
                        console.log({ res });
                        this.message = "Password updated successfully";
                        this.bgc = "success";
                        this.isVisible = true;
                        setTimeout(() => {
                            this.isVisible = false;
                        }, 3000);
                        this.getUser();
                    })
                    .catch((err) => {
                        console.log(err);
                        this.requiredFields.oldPassword = err.response.data.oldPassword;
                    });
            }
        },
    },
    async mounted() {
        await this.getUser();
    },
};
</script>

<style scoped lang="scss">
.navigation-tabs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin-top: 48px; */
    padding-right: 32px;
    padding-left: 48px;
    border-bottom: 2px solid #edeff2;
    margin-bottom: 25px;
}

.nav-links > a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding-top: 0px;
    padding-bottom: 24px;
    text-decoration: none;
    font-family: "Roboto";
    font-weight: 700;
    font-size: 14px;
    color: #7d8fb3;
    margin-right: 24px;
    position: relative;
}

.nav-links .nav-icon {
    font-size: 20px;
}

.nav-links img {
    margin-right: 12px;
}

.nav-links a::after {
    content: "";
    background: #7d8fb3;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 3px;
    transition: width 0.4s ease-in-out;
    border-radius: 25px;
}

.nav-links > a:hover::after {
    width: 100%;
}

.nav-links a.active::after {
    background: #2196f3;
    width: 100%;
}

.nav-links a.active * {
    color: #2196f3;
}

.input_group {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    border-radius: 20px;
    width: 100%;

    label {
        display: flex;
        align-items: center;
        font-weight: 500;
        line-height: 32px;
        color: #05152e;
        padding: 0;

        img {
            margin-right: 5px;
        }
    }

    .required {
        &::after {
            content: "*";
            color: #ff6969;
        }
    }

    .floating_label {
        position: absolute;
        top: 50%;
        left: 0;
        margin-left: 15px;
        padding: 0 1% 0 0;
        font-size: 14px;
        transform: translateY(-50%);
        pointer-events: none;
        background: #fff;
        transition: all 0.3s ease;
    }

    .floating_label.active {
        box-shadow: 0px 4px 10px rgba(21, 60, 245, 0.04);
        border-radius: 50px;
        background: #fff;
        transform: translateY(-140%);
    }

    input:focus + .floating_label {
        box-shadow: 0px 4px 10px rgba(21, 60, 245, 0.04);
        border-radius: 50px;
        background: #fff;
        transform: translateY(-150%);
        font-size: 12px;
    }

    input,
    textarea {
        border: 1px solid #ccd7ec;
        box-shadow: 0px 4px 10px rgba(21, 60, 245, 0.04);
        border-radius: 20px;
        width: 100%;
        padding: 0 1.5rem;
    }

    input {
        height: 3rem;
    }

    textarea {
        resize: none;
        padding-top: 1rem;
    }

    input[type="file"] {
        display: none;
    }

    .phone {
        height: 100%;
        border-radius: 20px;
        padding: 2%;

        & > :nth-child(2) {
            border-radius: 20px;
        }
    }
}

.err_msg {
    color: #ff6969;
    font-size: 14px;
    font-weight: 300;
    position: absolute;
    top: 100%;
}

.showpassword {
    position: absolute;
    top: 50%;
    right: 5%;
    transform: translateY(-50%);
}
</style>
