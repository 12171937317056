<template>
    <div class="talentProfileWrapper">
        <div class="talentData">
            <div>
                <img loading="lazy" decoding="async" :src="getImgUrl(candidateData?.Avatar)" alt="candidate picture" />
                <h1 class="name">{{ this.candidateData.First_name + " " + this.candidateData.Last_name }}</h1>
            </div>
            <h1 class="certRef">Skills verification n{{ this.verificationNbr }}</h1>
            <h1 class="title">Basic Skills for Employment.</h1>
        </div>
        <div class="talentResults">
            <div>
                <SpiderwebChart :data="chartData" :labels="chartLabels" />
            </div>
            <div class="btnsWrapper">
                <button @click="() => this.$router.push('/')">For recruiters</button>
                <button @click="() => this.$router.push('/talents')">For talents</button>
            </div>
        </div>
    </div>
</template>

<script>
import SpiderwebChart from "../charts/SpiderwebChart.vue";
import axios from "axios";
export default {
    name: "TalentProfile",
    components: { SpiderwebChart },
    props: [],
    data() {
        return {
            talentId: "",
            candidateData: {},
            candidateEvaluation: {},
            verificationNbr: "",
        };
    },
    computed: {},
    mounted() {
        this.talentId = this.$route.params.id;

        let config = {
            method: "get",
            maxBodyLength: Infinity,
            url: `https://server.go-platform.com/candidates/talentData/${this.talentId}`,
            headers: {},
        };

        axios
            .request(config)
            .then((response) => {
                this.candidateData = response.data.candidate;
                this.candidateEvaluation = response.data.candidateEvaluations;
                this.verificationNbr = response.data.verificationNbr;
            })
            .catch((error) => {
                console.log(error);
            });
    },
    methods: {
        getImgUrl(imgFileId) {
            if (imgFileId) {
                var image = `data:image/png;base64,${imgFileId}`;
                return image;
            }
            return require("../assets/Images/candidate-image.png");
        },
    },
};
</script>

<style scoped lang="scss">
.talentProfileWrapper {
    width: 100%;
    margin-top: 8%;
    padding: 3%;
    display: grid;
    grid-template-columns: 3fr 2fr;
    // opacity: 0.4;
    background: radial-gradient(50% 100% at 50.04% 49.53%, rgba(0, 174, 240, 0.16) 0%, rgba(0, 174, 240, 0) 100%);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    // filter: blur(158.36148071289062px);
    /* From https://css.glass */
    // background: rgba(255, 255, 255, 0.43);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8.9px);
    -webkit-backdrop-filter: blur(8.9px);
    border: 1px solid rgba(255, 255, 255, 0.3);

    .talentData {
        > :first-child {
            display: flex;
            flex-direction: row;
            align-items: center;

            img {
                width: 20%;
            }

            h1 {
                width: 100%;
                margin: 0;
                margin-left: 1%;
                text-align: left;
                color: #000;
                font-family: Inter;
                font-size: 56px;
                font-style: normal;
                font-weight: 700;
                line-height: 130px;
            }
        }

        .certRef {
            width: 100%;
            margin: 0;
            text-align: left;
            color: #000;
            font-family: Inter;
            font-size: 56px;
            font-style: normal;
            font-weight: 700;
            line-height: 130px;
        }

        .title {
            text-align: left;
            width: 100%;
            margin: 0;
            color: #00aef0;
            font-family: Inter;
            font-size: 72px;
            font-style: normal;
            font-weight: 700;
            line-height: 130px;
        }
    }

    .talentResults {
        display: flex;
        flex-direction: column;
        // justify-content: space-evenly;
        align-items: center;
    }

    .btnsWrapper {
        width: 96%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        margin: 2%;

        button {
            display: flex;
            width: 220.417px;
            height: 56px;
            // padding: 22px 62.417px 21px 61px;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            border-radius: 43px;
            background: #3361ff;
            color: #fff;
            text-align: center;
            font-family: DM Sans;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            letter-spacing: -0.788px;
        }
    }
}
</style>
